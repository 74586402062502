import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/main.scss'
import { Message } from 'element-ui';
import { Button, Select, Menu, MenuItem, Submenu, Dialog,Breadcrumb,BreadcrumbItem,Pagination,Form,FormItem,Input,Tooltip,Empty } from 'element-ui';
Vue.use(Button).use(Select).use(Menu).use(MenuItem).use(Submenu).use(Dialog).use(Breadcrumb).use(BreadcrumbItem).use(Pagination).use(Form).use(FormItem)
Vue.use(Input).use(Tooltip).use(Empty)
Vue.config.productionTip = false
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
