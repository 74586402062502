<template>
  <div class="footer">
    <img :src="logoS" class="logo" />
    <div class="content">
      <div class="content-box1">
        <div class="item">
          <h3>解决方案</h3>
          <span @click="go(1)">产业碳足迹</span>
          <span @click="go(2)">能碳管理平台</span>
          <span @click="go(3)">零碳城市</span>
          <span @click="go(4)">零碳园区</span>
        </div>
        <div class="item">
          <h3>产品</h3>
          <span @click="go(5)">数智双碳平台</span>
          <span @click="go(6)">产业碳足迹平台</span>
        </div>
        <div class="item">
          <h3>商务合作</h3>
          <div class="item-box">
            <div>
              <img src="@/assets/user.png" />
              <span>王女士</span>
            </div>
            <div>
              <img src="@/assets/phone.png" />
              <span>131 4699 6030</span>
            </div>
          </div>
          <h3>业务咨询</h3>
          <div class="item-box">
            <div>
              <img src="@/assets/user.png" />
              <span>王先生</span>
            </div>
            <div>
              <img src="@/assets/phone.png" />
              <span>199 2339 4477</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box2">
        <img :src="website" />
        <span>关注我们</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="bottom">
      <p>
        版权所有 © 2022 亿链纵横 渝公网安备 35080202351297号
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">渝ICP备2022012203号-2</a>
      </p>
      <p>地址：重庆市两江新区互联网产业园一期9栋12F</p>
    </div>
  </div>
</template>

<script>
import website from "@/assets/website.png";
import logoS from "@/assets/logo-white.svg";
export default {
  data() {
    return {
      logoS: logoS,
      website: website,
    };
  },
  methods: {
    go(type) {
      if (type == 1) {
        if (this.$route.fullPath != "/IndustrialCarbon") {
          this.$router.push("/IndustrialCarbon");
        }
      } else if (type == 2) {
        if (this.$route.fullPath != "/energyConsumption") {
          this.$router.push("/energyConsumption");
        }
      } else if (type == 3) {
        if (this.$route.fullPath != "/zeroCarbonCity") {
          this.$router.push("/zeroCarbonCity");
        }
      } else if (type == 4) {
        if (this.$route.fullPath != "/zeroCarbonPark") {
          this.$router.push("/zeroCarbonPark");
        }
      } else if (type == 5) {
        if (this.$route.fullPath != "/numeracy") {
          this.$router.push("/numeracy");
        }
      } else if (type == 6) {
        if (this.$route.fullPath != "/carbonFootprint") {
          this.$router.push("/carbonFootprint");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.footer {
  width: 1400px;
  margin: 0 auto;
  .logo {
    margin-top: 24px;
    width: 123px;
    height: 31px;
    box-sizing: border-box;
  }
  .content {
    width: 1400px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding-bottom: 18px;
    .content-box1 {
      flex: 1;
      display: flex;
      h3 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 20px;
      }
      h3:nth-of-type(2) {
        margin-top: 24px;
      }
      .item:nth-child(1) {
        span {
          cursor: pointer;
        }
        span:hover {
          color: #00dd62;
        }
      }
      .item:nth-child(2) {
        span {
          cursor: pointer;
        }
        span:hover {
          color: #00dd62;
        }
      }
      > .item {
        width: 245px;
        display: flex;
        flex-direction: column;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
    .item-box {
      width: 400px;
      display: flex;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
      }
      img {
        margin-right: 8px;
      }
      div:nth-child(2) {
        margin-left: 64px;
      }
    }
    .content-box2 {
      width: 135px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.3;
  }
  .bottom {
    width: 100%;
    margin-top: 16px;
    p {
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999;
      line-height: 20px;
      a {
        color: #999;
        text-decoration: none;
      }
    }
  }
}
</style>
