import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/', component: Layout, redirect: '/home', children: [
      { path: '/home', meta: { active: '/home', title: '亿链纵横-首页' }, component: () => import('@/views/home/index.vue') },
      { path: '/numeracy', meta: { active: '/numeracy', title: '亿链纵横-数智双碳平台' }, component: () => import('@/views/products/numeracy/idnex.vue') },
      { path: '/carbonFootprint', meta: { active: '/carbonFootprint', title: '亿链纵横- 产业碳足迹平台' }, component: () => import('@/views/products/zeroCarbonCity/index.vue') },

      { path: '/zeroCarbonCity', meta: { active: '/zeroCarbonCity', title: '亿链纵横-零碳城市' }, component: () => import('@/views/theSolution/zeroCity/index.vue') },
      { path: '/IndustrialCarbon', meta: { active: '/IndustrialCarbon', title: '亿链纵横-产业碳足迹' }, component: () => import('@/views/theSolution/IndustrialCarbon/index.vue') },
      { path: '/zeroCarbonPark', meta: { active: '/zeroCarbonPark', title: '亿链纵横-零碳园区' }, component: () => import('@/views/theSolution/zeroCarbonPark/index.vue') },
      // { path: '/zeroCarbonMechanism', meta: { active: '/zeroCarbonMechanism', title: '零碳机关' }, component: () => import('@/views/theSolution/zeroCarbonMechanism/index.vue') },
      { path: '/energyConsumption', meta: { active: '/energyConsumption', title: '亿链纵横-能碳监管' }, component: () => import('@/views/theSolution/energyConsumption/index.vue') },

		{ path: '/information', meta: { active: '/information', title: '亿链纵横-资讯中心' }, component: () => import('@/views/information/index.vue') },
		  { path: '/informationDetail/:id', meta: { active: '/information', title: '亿链纵横-资讯中心详情' }, component: () => import('@/views/information/detail.vue') },
		  { path: '/consult', meta: { active: '/consult', title: '亿链纵横-咨询中心' }, component: () => import('@/views/consult/index.vue') },
	  { path: '/probation', meta: { active: '/probation', title: '亿链纵横-免费试用申请' }, component: () => import('@/views/probation/index.vue') },
      { path: '/joinUs', meta: { active: '/joinUs', title: '亿链纵横-加入我们' }, component: () => import('@/views/joinUs/index.vue') },
      { path: '/aboutUs', meta: { active: '/aboutUs', title: '亿链纵横-关于我们' }, component: () => import('@/views/aboutUs/index.vue') },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
